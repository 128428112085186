const translations = {
  en: {
    general: {
      password_header: "Password services",
      password: "Password",
      new_password: "New password",
      repeat_password: "Repeat password",
      username: "Username",
      change_password: "Change password",
      change_password_for: "Change password for ",
      confirm_password_change: "Change password",
      password_changed: "Your password has been changed.",
      password_score: "Password strength",
      back_to_main_page: "Back to login page",
      log_in: "Log in",
      log_in_ext: "Log in with ID-porten",
      show_password: "Show password",
      hide_password: "Hide password",
      cancel: "Cancel",
      next: "Next",
      done: "Done",
      password_updated: "Your password has been updated",
      logged_out: "Logged out",
      auto_logged_out: "You have been automatically logged out of the service.",
      social_security_nr: "Norwegian national ID number (11 digits)",
      student_nr: "Student number (6 digits)",
      go_back: "Back",
      forgotten_password: "Forgotten or expired password",
      use_sms_token: "Get a single use code by SMS",
      select_recovery_method:
        "If you have forgotten your password, or let it expire, you can still log in with a single use code received by SMS, or by using BankID, Buypass ID or Commfides in ID-porten.",
      send_token: "Send token",
      now_logged_out: "You are now logged out.",
      password_delay_notice:
        "Remember, it can take up to an hour before you can log in to our IT-services. Connection to wifi must be reconfigured manually.",
      logged_out_inactive:
        "You were logged out due to not making any changes for the last %{minutes} minutes.",
      contact_help: "Please contact your IT department for more information.",
      contact_help_short: "your IT department.",
      new_user_common:
        "If you are a new user and have received an SMS, email, or letter about setting up a password, you can do it here. If you have registered your mobile phone, you can click 'Get a single use code by SMS' below. If you have set up BankID, Buypass ID eller Commfides with a Norwegian national identification number, you can also use the 'Log in with ID-porten'.",
      choose_user: {
        choose_user: "For which account do you want to change password?",
        suspended_users: "Locked accounts",
        suspended_users_info:
          "You can still change your password, but to reopen the accounts you have to contact ",
        no_users: "You have no account at"
      },
      after_password_change: {
        header_pt1: "The password for ",
        header_pt2: " has been changed",
        continue: "Change the password for another account"
      },
      warning_oidc_session_expired:
        "You have been logged out, because your session expired.",
      warning_account_locked:
        "This account is locked, and does not have access to the IT services. ",
      finish_and_logout: "Finish and log out",
      logout: "Log out",
      enter_token: "Confirm token",
      enter_token_info: "Enter the token you received by SMS.",
      token: "Token",
      confirm: "Confirm",
      mobile_phone_nr: "Mobile phone number",
      forgotten_username: "Forgotten username",
      forgotten_username_info: "If you have forgotten your username you can recover it by identifying yourself with your Norwegian national ID number or your student number. You can also log in with bankID, Buypass ID or Commfides via ID-porten.",
      forgotten_username_only_oidc_info: "If you have forgotten your username you can recover it by logging in with bankID, Buypass ID or Commfides via ID-porten.",
      get_usernames_info: "To find your username, you must identify yourself with your Norwegian national ID number or your student number.",
      get_username: "Get username",
      your_username: "Your username",
      your_usernames: "Your usernames",
      your_username_info: "To change your password, click the link below.",
      your_usernames_info:
        "To change the password of one of your users, click one of the links below.",
      finish: "Finish",
      change_language: "Norsk",
      yes: "Yes",
      no: "No",
      cancel_popup_text: "Are you sure you want to cancel?",
      continue: "Continue",
      password_strength_approved: "Password strength approved",
      password_strength_not_approved: "Password strength not approved",
      password_known_leaked: "This password is a weak / leaked password, try a different one",
      new_user: "New user",
      passphrase_modal_header: "Passphrases",
      passphrase_tip:
        'Rather than making a "traditional password" consisting of letters, numbers and special characters, you can use an ordinary sentence. Sentences are often easier to remember.',
      passphrase_ex1: '"I have a really secure password!"',
      passphrase_ex2: '"My password is so easy to remember!"',
      examples: "Examples:",
      new_password_rules: "New password rules",
      intropage_text:
        "It is now optional how you construct your password at The University of Oslo.",
      intropage_rules_list_header: "There are only two rules:",
      intropage_rule_1: "Obtain a password strength of 10, more on this below",
      intropage_rule_2: "You may not use your own name or date of birth",
      sentences: "Sentences",
      words: "Words",
      numbers: "Numbers",
      special_chars: "Special Characters",
      spaces: "Spaces",
      category_use:
        "Using several of these different categories will cause your password strength to increase more quickly.",
      feedback_page_header: "Help us improve our password services?",
      feedback_page_info:
        "The survey takes approximately 2-5 minutes, you will remain anonymous.",
      feedback_page_decline: "No thanks",
      feedback_page_accept: "Yes please",
      country_code: "Country code",
      timeout_popup_header: "Automatic logout",
      timeout_popup_text:
        "You will be automatically logged out in %{secs} seconds. Click OK to stay logged in.",
      mfa_page_header: "Two-factor authentication",
      otp_off_text: "Local two-factor authentication has been turned off",
      otp_header: "Local 2FA",
      otp_on: "On ",
      otp_off: "Off ",
      otp_change_status_info: "The status will be updated for all accounts linked to your user. ",
      otp_confirm_on: "Turn on",
      otp_confirm_off: "Turn off",
      otp_popup_header: "Turn off two-factor authentication?",
      otp_popup_text: "To re-enable two-factor authentication you will have to reconfigure the authentication app.",
      use_otp: "Configure local 2FA",
      otp_info: "To enable two-factor authentication:",
      otp_toggle_info: "You can configure local two-factor authentication. This is used for local services, like ssh, operational services and Feide used by -drift accounts. ",
      enable_otp_text: {
        header_1: "Set up two-factor authentication",
        page_1_info: "Open the authentication app and scan in the QR code with the camera on your mobile",
        page_1_info_alt: "Or type the following code manually:",
        page_1_info_end: "When the code is registered, you will see a 6 digit code in the app which will be renewed every x second",
        header_2: "Verify the authentication app",
        page_2_info: "Type in the 6 digit code from the app to ensure that everything is working",
        page_2_info_alt: "6 digit code",
        page_2_not_valid: "Invalid code",
        header_3: "Verified",
        page_3_info: "From now on you use the authentication app to log in to services that require it",
        page_3_delay: "It may take up to 30 minutes before you can use the new setup for two-factor authentication."
      },
      reset_mfa_text: {
        reset_mfa_header: "Reset two-factor authentication",
        reset_mfa_blurb: "Reset 2FA for your Feide-accounts here.",
        reset_mfa_info: "For which account do you want to reset two-factor authentication?",
        azure_mfa_info: "You can reset two-factor authentication used with Feide and MS365. ",
        no_mfa_set: "Accounts without two-factor authentication",
        no_mfa_info: "Information on how to configure two-factor for your accounts can be found at ",
        no_users_found: "There are currently no accounts to reset two-factor authentication for.",
        popup_header: "Reset 2FA for this user?",
        popup_info: "This will reset all methods of two-factor authentication set up for the user ",
        popup_confirm_off: "Reset",
        popup_reset_finished: " Two-factor authentication has now been reset. ",
        popup_reset_error: "An error occured while trying to reset two-factor authentication. "
      },
      itregulations: {
        header: "IT regulations",
        linkText: "View the IT regulations here",
        accept: "Accept",
      },
      pin_code: {
        pin_code: "PIN code",
        header: "Change PIN code for access card",
        info: "If you have an account at UiO you can set a new PIN code on your access card here.",
        not_available: "If you are not able to log in by using BankID, Buypass ID or Commfides in ID-porten you will have to visit the ",
        card_centre: "Card Centre.",
        confirm_change: "Change PIN code",
        changed_header: "PIN code has been updated",
        several_cards: "If you have more than one access card the PIN code will update for all of them.",
        no_cardholder: "It doesn't appear that you have any active access cards at UiO. ",
        go_to: "Access cards can be obtained at the ",
      },
    },
    formErrors: {
      requiredField: "This field is required.",
      passwordMismatch: "The passwords are not equal.",
      invalidPasswordChar:
        "The password contains one or more invalid characters.",
      passwordScoreTooLow: "The password isn't strong enough.",
      passwordTooLong: "The password is too long, the limit is %{length} characters.",
      leakedPassword: "The password is a known leaked password, try a different password",
      identifierLength:
        "The field must consist of exactly 6 digits (student number) or 11 digits (Norwegian national ID).",
      onlyNumbers: "This field must consist of numbers only.",
      mobilePhoneNr: "Phone numbers can only consist of numbers.",
      countryCode: "Invalid country code.",
      pinMismatch:"The PIN code must consist of exactly 4 digits",
      noLetters:"Letters are not allowed",
      identicalNumbers:"Four identical digits are not allowed",
      sequentialNumbers:"Four sequential digits are not allowed",
    },
    serverErrors: {
      requestSmsToken:
        "We were unable to identify you based on the supplied information. Please verify that all fields have been filled out correctly, and that the mobile phone number is registered in our systems.",
      invalidSmsToken:
        "The token is incorrect. Please verify that you have entered it correctly.",
      invalidUsernameOrPassword: "Invalid username and/or password.",
      serverDown:
        "The server appears to be down. Contact your local IT support if this problem persists.",
      missingSession:
        "A valid session is required in order to access this resource. Does your browser accept cookies?",
      rateLimit:
        "You have had too many attempts in a short period of time. Please wait a few minutes.",
      invalidNewPassword:
        "Your new password could not be set because it is identical to a previous password.",
      newPasswordContainsUsername:
        "Your new password could not be set because it contains your username.",
      newPasswordContainsPersonname:
        "Your new password could not be set because it contains parts of your name.",
      invalidPhoneNr: "Invalid phone number",
      invalidPhoneNrRegion:
        "Your phone number is from an unsupported country. Please contact your local IT support.",
      inactiveAccount:
        "This account is inactive. Please contact your local IT support.",
      reservedAccountGeneric:
        "This account is reserved from using this service. Please contact your local IT support.",
      reservedAccountSelf:
        "You have reserved yourself from using this service. Please contact your local IT support.",
      notValidPinCode:
        "The new PIN code is not valid. Try another combination."
    }
  },
  nb: {
    general: {
      password_header: "Passordtjenester",
      password: "Passord",
      new_password: "Nytt passord",
      repeat_password: "Gjenta passord",
      username: "Brukernavn",
      change_password: "Bytt passord",
      change_password_for: "Bytt passord for ",
      confirm_password_change: "Bytt passord",
      password_changed: "Passordet ditt ble endret.",
      password_delay:
        "Det kan ta inntil en time før passordet vil fungere på alle tjenester ved UiO.",
      password_score: "Passordstyrke",
      back_to_main_page: "Tilbake til innloggingsiden",
      log_in: "Logg inn",
      log_in_ext: "Logg inn via ID-porten",
      show_password: "Vis passord",
      hide_password: "Skjul passord",
      cancel: "Avbryt",
      next: "Neste",
      done: "Ferdig",
      password_updated: "Passordet ditt ble endret",
      logged_out: "Utlogget",
      auto_logged_out: "Du har automatisk blitt logget ut av tjenesten.",
      social_security_nr: "Fødselsnummer (11 siffer)",
      go_back: "Tilbake",
      student_nr: "Studentnummer (6 siffer)",
      forgotten_password: "Glemt eller utgått passord",
      use_sms_token: "Få engangskode på SMS",
      select_recovery_method:
        "Hvis du har glemt passordet ditt, eller latt det gå ut på dato, kan du fortsatt logge inn med engangskode på SMS, eller med BankID, Buypass ID eller Commfides via ID-porten.",
      send_token: "Send engangskode",
      now_logged_out: "Du er nå logget ut.",
      password_delay_notice:
        "Husk at det kan ta inntil en time før du får logget inn på IT-tjenestene igjen. Tilkobling til wifi må konfigureres på nytt manuelt.",
      logged_out_inactive:
        "Applikasjonen har logget deg ut fordi du ikke har foretatt noen endringer de siste %{minutes} minuttene.",
      contact_help: "Ta kontakt med din IT-avdeling for mer informasjon.",
      contact_help_short: "din IT-avdeling.",
      new_user_common:
        "Er du ny bruker og har fått SMS, e-post eller brev om opprettelse av passord, kan du gjøre det her. Hvis du har registrert mobiltelefonen din, kan du trykke 'Få engangskode på SMS' under. Hvis du ikke har registrert mobiltelefon men har BankID, Buypass ID eller Commfides, kan du klikke på 'Logg in via ID-porten.",
      choose_user: {
        choose_user: "Hvilken brukerkonto vil du endre passord for?",
        suspended_users: "Sperrede brukerkontoer",
        suspended_users_info:
          "Du kan fortsatt bytte passord, men for å gjenåpne kontoene må du ta kontakt med ",
        no_users: "Du har ingen brukerkonto ved"
      },
      after_password_change: {
        header_pt1: "Passordet for ",
        header_pt2: " ble endret",
        continue: "Bytt passordet for en annen konto"
      },
      warning_oidc_session_expired:
        "Du har blitt automatisk utlogget, siden sesjonen din er utløpt.",
      warning_account_locked:
        "Denne kontoen er sperret, og har ikke tilgang til IT-tjenestene. ",
      finish_and_logout: "Avslutt og logg ut",
      logout: "Logg ut",
      enter_token: "Bekreft engangskode",
      enter_token_info:
        "Skriv inn engangskoden som ble sendt til din mobiltelefon via SMS.",
      token: "Engangskode",
      confirm: "Bekreft",
      mobile_phone_nr: "Mobiltelefonnummer",
      forgotten_username: "Glemt brukernavn",
      forgotten_username_info: "Hvis du har glemt brukernavnet ditt kan du få det ved å identifisere deg med studentnummer eller fødselsnummer. Du kan også logge inn med BankID, Buypass ID eller Commfides via ID-porten.",
      forgotten_username_only_oidc_info: "Hvis du har glemt brukernavnet ditt kan du få det ved logge inn med BankID, Buypass ID eller Commfides via ID-porten.",
      get_usernames_info: "Identifiser deg med ditt fødselsnummer eller studentnummer for å få brukernavnet ditt.",
      get_username: "Få brukernavn",
      your_username: "Ditt brukernavn",
      your_usernames: "Dine brukernavn",
      your_username_info: "Klikk på lenka nedenfor for å bytte passord.",
      your_usernames_info:
        "Klikk på en av lenkene nedenfor for å bytte passord.",
      finish: "Avslutt",
      change_language: "English",
      yes: "Ja",
      no: "Nei",
      cancel_popup_text: "Er du sikker på at du vil avbryte?",
      continue: "Fortsett",
      password_strength_approved: "Passordstyrke godkjent",
      password_strength_not_approved: "Passordstyrke ikke godkjent",
      password_known_leaked: "Passordet er et svakt / lekket passord, prøv et annet",
      new_user: "Ny bruker",
      passphrase_modal_header: "Passordsetninger",
      passphrase_tip:
        "Til forskjell fra «tradisjonelle passord», som består av en kombinasjon av små og store bokstaver, tall og spesialtegn, kan du nå skrive en helt vanlig setning, som ofte er lettere å huske.",
      passphrase_ex1: '"Jeg har et kjempesikkert passord!"',
      passphrase_ex2: '"Passordet mitt er kjempelett å huske!"',
      examples: "Eksempler:",
      new_password_rules: "Nye passordregler",
      intropage_text:
        "Det er nå valgfritt hvordan du bygger opp passordet ditt.",
      intropage_rules_list_header: "Kun to regler:",
      intropage_rule_1: "Oppnå en passordstyrke på 10, mer om dette nedenfor",
      intropage_rule_2:
        "Du kan ikke bruke ditt personlige navn eller din fødselsdato",
      intropage_allowed_categories:
        "Dette betyr at du kan bruke en eller flere av følgende kategorier:",
      sentences: "Setninger",
      words: "Ord",
      numbers: "Tall",
      special_chars: "Spesialtegn",
      spaces: "Mellomrom",
      category_use:
        "Jo flere av disse kategoriene du tar i bruk i ditt nye passord, desto raskere vil passordstyrken øke.",
      feedback_page_header: "Vil du hjelpe oss å forbedre passordtjenesten?",
      feedback_page_info: "Undersøkelsen tar 2-5 minutter, og du er anonym.",
      feedback_page_decline: "Nei takk",
      feedback_page_accept: "Ja, jeg vil delta",
      country_code: "Landskode",
      timeout_popup_header: "Automatisk utlogging",
      timeout_popup_text:
        "Du vil bli automatisk utlogget om %{secs} sekunder. Trykk på OK-knappen for å holde deg innlogget.",
      mfa_page_header: "Tofaktorautentisering ",
      otp_off_text: "Lokal tofaktorautentisering er slått av",
      otp_header: "Lokal tofaktor",
      otp_on: "På ",
      otp_off: "Av ",
      otp_change_status_info: "Status vil bli oppdatert for alle kontoer knyttet til din bruker.",
      otp_confirm_on: "Slå på",
      otp_confirm_off: "Slå av",
      otp_popup_header: "Slå av tofaktorautentisering?",
      otp_popup_text: "Du må rekonfigurere autentiserings-appen hvis du velger å slå på tofaktorautentisering igjen.",
      use_otp: "Konfigurer lokal tofaktor",
      otp_info: "For å slå på tofaktorautentisering:",
      otp_toggle_info: "Du kan skru av og på lokal tofaktor som brukes for interne tjenester, som ssh, driftstjenester, og Feide for driftsbrukere. ",
      enable_otp_text: {
        header_1: "Sett opp tofaktorautentisering",
        page_1_info: "Åpne autentiserings-appen og skann inn QR-koden med kameraet på mobilen",
        page_1_info_alt: "Eller skriv inn følgende kode manuelt:",
        page_1_info_end: "Når koden er registrert, vil du se en 6-sifret kode i appen som fornyes hvert x sekund",
        header_2: "Verifisér autentiserings-appen",
        page_2_info: "Skriv inn den 6-sifrede koden fra appen for å være sikker på at alt fungerer",
        page_2_info_alt: "6-sifret kode",
        page_2_not_valid: "Ugyldig kode",
        header_3: "Verifisert",
        page_3_info: "Fra nå av bruker du autentiseringsappen til å logge inn i tjenester som krever det",
        page_3_delay: "Det kan ta opptil 30 minutter før du kan ta i bruk det nye oppsettet for tofaktorautentisering."
      },
      reset_mfa_text: {
        reset_mfa_header: "Nullstill tofaktor for Feide",
        reset_mfa_blurb: "Her kan du nullstille 2FA satt opp for dine Feide-kontoer.",
        reset_mfa_info: "Hvilken brukerkonto vil du nullstille tofaktorautentisering for?",
        azure_mfa_info: "Du kan nullstille tofaktor som brukes for Feide og MS365. ",
        no_mfa_set: "Kontoer uten tofaktorautentisering",
        no_mfa_info: "For informasjon om hvordan å sette opp tofaktor for dine kontoer besøk siden for ",
        no_users_found: "Det finnes ingen kontoer å nullstille tofaktorautentisering for nå.",
        popup_header: "Nullstill tofaktor for denne brukeren?",
        popup_info: "Dette vil nullstille alle metoder for tofaktorautentisering som er satt opp for brukeren ",
        popup_confirm_off: "Nullstill",
        popup_reset_finished: " Tofaktorautentisering er nullstilt.",
        popup_reset_error: "Det oppstod en feil under nullstilling av tofaktorautentisering."
      },
      itregulations: {
        header: "IT-reglement",
        linkText: "Du kan lese IT-reglementet her ",
        accept: "Ok"},
      pin_code : {
        pin_code: "PIN-kode",
        header: "Endre PIN-kode for adgangskort",
        info: "Hvis du har en brukerkonto ved UiO kan du sette en ny PIN-kode på adgangskortet ditt her.",
        not_available: "Hvis du ikke kan logge inn med BankID, Buypass ID eller Commfides via ID-porten må du gå til ",
        card_centre: "Kortsenteret.",
        confirm_change: "Bytt PIN-kode",
        changed_header: "PIN-koden din er nå endret",
        several_cards: "Dersom du har flere adgangskort endrer du PIN-kode på alle.",
        no_cardholder: "Det ser ikke ut til at du har noen aktive adgangskort ved UiO. ",
        go_to: "Dersom du trenger et adgangskort kan du gå til ",
      },
    },
    formErrors: {
      requiredField: "Dette feltet er påkrevd.",
      passwordMismatch: "Passordene er ikke like.",
      invalidPasswordChar:
        "Passordet inneholder ett eller flere ugyldige tegn.",
      passwordScoreTooLow: "Passordet er ikke sterkt nok.",
      passwordTooLong: "Passordet er for langt, maksimal lengde er %{length} tegn.",
      leakedPassword: "Passordet er et kjent lekket passord, forsøk med et annet passord",
      identifierLength:
        "Feltet må bestå av nøyaktig 6 (for studentnummer) eller 11 (for fødselsnummer) sifre.",
      onlyNumbers: "Dette feltet kan kun inneholde tall.",
      mobilePhoneNr: "Telefonnummeret kan kun bestå av tall.",
      countryCode: "Ugyldig landskode.",
      pinMismatch:"PIN-koden må bestå av nøyaktig 4 sifre",
      noLetters:"Bokstaver er ikke tillat",
      identicalNumbers:"Fire like siffer er ikke tillatt",
      sequentialNumbers:"Fire sekvensielle tall er ikke tillatt",
    },
    serverErrors: {
      requestSmsToken:
        "Vi kunne ikke identifisere deg ut ifra oppgitt informasjon. Vennligst sjekk at feltene er korrekt utfylte, og at mobiltelefonnummeret er registrert i våre systemer.",
      invalidSmsToken:
        "Ugyldig engangskode. Vennligst kontroller at du har tastet inn koden riktig.",
      invalidUsernameOrPassword: "Ugyldig brukernavn og/eller passord.",
      serverDown:
        "Kunne ikke kommunisere med serveren. Kontakt lokal-IT om dette problemet vedvarer.",
      missingSession:
        "Gyldig sesjon er påkrevet for å få tilgang. Tillater nettleseren din informasjonskapsler (cookies)?",
      rateLimit:
        "Du har forsøkt for mange ganger på kort tid. Vennligst vent noen minutter.",
      invalidNewPassword:
        "Kunne ikke sette nytt passord fordi det er likt et tidligere passord.",
      newPasswordContainsUsername:
        "Kunne ikke sette nytt passord fordi det inneholder brukernavnet ditt.",
      newPasswordContainsPersonname:
        "Kunne ikke sette nytt passord fordi det inneholder deler av navnet ditt.",
      invalidPhoneNr: "Ugyldig telefonnummer",
      invalidPhoneNrRegion:
        "Telefonnummeret tilhører et land som ikke støttes. Vennligst ta kontakt med lokal-IT.",
      inactiveAccount:
        "Denne brukerkontoen er ikke aktiv. Vennligst ta kontakt med lokal-IT.",
      reservedAccountGeneric:
        "Denne brukerkontoen er reservert fra bruk av denne tjenesten. Vennligst ta kontakt med lokal-IT.",
      reservedAccountSelf:
        "Du har reservert deg fra bruk av denne tjenesten. Vennligst ta kontakt med lokal-IT.",
      notValidPinCode:
        "Den nye PIN-koden er ikke gyldig. Prøv en annen kombinasjon."
    }
  }
};

export default translations;
